








































































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import * as actionTypes from '@/store/actionTypes'
import DiscardPassword from '../DiscardPassword.vue'
import type BaseInput from './BaseInput.vue'
import { format } from 'quasar'

const { capitalize } = format

@Component({
  name: 'AuthScreen',
  components: {
    DischargeModal: () => import('../DiscardPassword.vue'),
    BaseInput: () => import('./BaseInput.vue'),
  },
})
export default class AuthScreen extends Vue {
  $refs!: {
    passInput: BaseInput;
  }

  @Getter canDiscardPass!: boolean

  loading = false
  hint = ''
  errLabel = ''
  pass = ''

  showError = true

  isDiscardDialogOpened = false
  showRememberPassError = false

  get label () {
    if (this.errLabel) return this.errLabel
    return this.$t('auth.twoFactorAuth.authEnabled')
  }

  get forgotPassLabel () {
    return this.showRememberPassError
      ? this.$t('auth.twoFactorAuth.forgotPasswordError')
      : this.$t('auth.twoFactorAuth.forgotPasswordQuestion')
  }

  get nextText (): string {
    return capitalize(this.$t('common.next').toString())
  }

  /**
   * Try to auth by input password
   * If success - set login = true
   * If error - set label and hint from response
   */
  async auth () {
    this.errLabel = ''
    this.loading = true
    try {
      await this.$store.dispatch(actionTypes.TFA_CHECK_PASS, this.pass)
      this.$emit('auth', this.pass)
    } catch (e) {
      this.hint = e.details?.hint ?? ''
      this.errLabel = e.details?.password ?? e.error
      this.$refs.passInput.focus()
      this.showError = true
    }
    this.loading = false
  }

  openDiscardDialog () {
    this.isDiscardDialogOpened = true
    this.$q
      .dialog({
        component: DiscardPassword,
        parent: this,
        isOpened: this.isDiscardDialogOpened,
      })
      .onCancel(() => {
        this.$emit('close')
        this.isDiscardDialogOpened = false
      })
  }

  forgotPassAction () {
    this.canDiscardPass
      ? this.openDiscardDialog()
      : (this.showRememberPassError = true)
  }
}
