












































import { CodeTFA } from '@/store/modules/profile/types'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getDateWithDifference } from '@/api/v3/DataGenerator'
import * as actionTypes from '@/store/actionTypes'
import CodeInputStep from './components/CodeInputStep.vue'
import ModalHeader from './components/ModalHeader.vue'
import type { QDialog } from 'quasar'

@Component({
  name: 'DiscardPassword',
  components: {
    ModalHeader,
    CodeInputStep,
  },
})
export default class DiscardPassword extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly isOpened!: boolean

  $refs!: {
    dialog: QDialog;
    CodeInput: CodeInputStep;
  }

  codeLength = 4
  codeErr = ''

  email = ''

  loading = false

  codeTimeout = -1
  codeInterval: number | null = null

  async created () {
    await this.sendCode()
  }

  async sendCode () {
    try {
      const res: CodeTFA = await this.$store.dispatch(
        actionTypes.TFA_SEND_DISCARD_CODE,
      )
      this.codeLength = res.codeLength ?? 4
      this.codeErr = ''
      this.email = res.email ?? ''

      this.codeTimeout = (
        new Date(res.nextCodeAt).getTime() -
        getDateWithDifference('ts')
      ) / 1000

      this.codeInterval = this.codeInterval || setInterval(() => {
        if (this.codeTimeout >= 0) {
          this.codeTimeout--
        } else {
          this.codeInterval && clearInterval(this.codeInterval)
          this.codeInterval = null
        }
      }, 1000)
    } catch (e) {
      this.codeErr = e.details?.email ?? ''
      this.$refs.CodeInput.focus()
    }
  }

  async codeInput (value: string) {
    this.loading = true
    this.codeErr = ''
    try {
      await this.$store.dispatch(actionTypes.TFA_DISCARD_PASS, value)
      this.close()
      this.$q.notify({
        message: this.$t('auth.twoFactorAuth.passDeleted').toString(),
        color: 'primary',
        textColor: 'white',
        position: 'top',
        timeout: 5000,
        actions: [{ label: this.$t('common.close'), color: 'white' }],
        badgeClass: 'hidden',
      })
    } catch (e) {
      this.codeErr = e.details?.code ?? e.error
      this.$refs.CodeInput.focus()
    }
    this.loading = false
  }

  // Close modal
  close () {
    this.$emit('hide')
  }

  show () {}

  hide () {
    this.$refs.dialog.hide()
  }
}
